import React from "react";
import DataRequestContainer from "./data-request.styles";
import Typography from "@eig-builder/core-ui/Typography";

const DataRequestComponent = () => {
  return (
    <DataRequestContainer>
      <Typography variant="h2" gutterBottom>
        Data Request Policy
      </Typography>
      <ol>
        <li>
           <Typography variant="body1" gutterBottom>
          <b>Privacy Notice. </b>Please see Sitebuilder’s{" "}
          <DataRequestContainer.link href="https://newfold.com/privacy-center">
            Privacy Notice
          </DataRequestContainer.link>{" "}
          for further information on what information Sitebuilder collects,
           how it is used and when it may be disclosed. Subject to Sitebuilder's Privacy Notice, 
           this Data Request Policy describes the types of data requests that Sitebuilder may receive and 
          how Sitebuilder may respond to such requests. 
        </Typography>
        </li>

  
        <li>
          <Typography variant="body1"  gutterBottom>
          <b>Types of Requests.</b> Sitebuilder may receive the following types of requests concerning its 
          Subscribers: 
          </Typography>
        </li>
        <ol type="A">
          <li>
            <Typography variant="body1" gutterBottom>
            <b> Data Requests.</b> A data request is a request for information or documents relating to
             Subscriber accounts in connection with official criminal investigations or other legal 
             proceedings. Except in limited emergency situations (see below), 
             Sitebuilder requires data requests to be made through formal U.S. legal process and procedures,
              and such requests will be handled in accordance with applicable law. Examples of acceptable
               data requests include: 
            </Typography>
          </li>
          <ol type="i">
            <li>
              <Typography variant="body1" gutterBottom>
              Subpoenas
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
              Court Orders
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
                Search Warrants
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
              Legal process received from outside the United States may require a Mutual
              Legal Assistance Treaty (MLAT) with the United States 
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
              Other forms of legal process such as Civil Investigative Demands.
              </Typography>
            </li>
          </ol>
          <li>
            <Typography variant="body1" gutterBottom>
            <b>Preservation Requests.</b> A preservation request asks Sitebuilder to preserve Subscriber account 
            records in connection with official criminal investigations or other official legal proceedings. 
            Preservation requests must include the following information:
            </Typography>
          </li>
          <ol type="i">
            <li>
              <Typography variant="body1" gutterBottom>
              Identification of the account(s) at issue (as further described in Section 6 below);
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
              Identification of the investigating agency and/or specific pending official proceedings 
              (requests must be signed on law enforcement letterhead); 
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
              Assurances that the requesting agency or individual is taking steps to 
              obtain appropriate legal process for access to the data that Sitebuilder 
              is being asked to preserve; and 
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
                A valid return email address and phone number.
              </Typography>
            </li>
          </ol>
          <li>
            <Typography variant="body1" gutterBottom>
            <b>Emergency Requests.</b> An emergency request is only appropriate in cases involving 
            imminent serious bodily harm or death. We respond to emergency
             requests when we believe in good faith that such harm may occur if we do not respond without delay
            </Typography>
          </li>
        </ol>

        <li>
          <Typography variant="body1" gutterBottom>
            <b>Disclosure of Non-Public Domain Registrant Information. </b>
            As the sponsoring registrar or reseller of certain domain names governed by the Internet 
            Corporation for Assigned Names and Numbers (“ICANN”), 
            Sitebuilder must permit third parties with a legitimate interest to access masked (non-public) 
            registrant information subject to the data subject’s rights under the General Data Protection Regulation (“GDPR”) 
            or other similar privacy laws as applicable. 
          </Typography>
        </li>

        <li>
        <Typography variant="body1" gutterBottom>
        <b>Notice to Sitebuilder Subscribers.</b>Sitebuilder's policy is to notify its Subscribers of requests 
        for their data unless Sitebuilder 
        is prohibited from doing so by applicable law or court order. 
        </Typography>
        </li>
       
        <li>
          <Typography variant="body1" gutterBottom>
          <b>Information to Include in a Request.</b> The following information must be included in a 
          request for Subscriber  data:
          </Typography>
          <ol type="A">
          <li>
            <Typography variant="body1" gutterBottom>
            First and last name of the Subscriber and email address 
            associated with the account; and 
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
            Domain name, username and/or IP address associated with the account.
            </Typography>
          </li>
          <Typography variant="body1" gutterBottom>
            <b>NOTE:</b> A Request that only includes an IP address may be overly broad and insufficient  
            for Sitebuilder to locate a Subscriber account. Please include additional identifiers such
            domain name or username whenever possible.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Sitebuilder may not be able to respond to a request without this
            information.
          </Typography>
          <Typography variant="body1" gutterBottom>
          Additionally, Sitebuilder reserves the right to request a copy of the compliant and any supporting 
          documentation that demonstrates how the information requested is related to any pending litigation, 
          formal legal proceeding or investigation. 
          </Typography>
        </ol>
        </li>
       
        <li>
          <Typography variant="body1" gutterBottom>
          <b>Sending a Request to Sitebuilder.</b> All data requests should be sent to:
          </Typography>
        </li>
        <Typography variant="body1" gutterBottom>
        
        </Typography>
        <Typography variant="body1">Sitebuilder</Typography>
        <Typography variant="body1">Legal Department</Typography>
        <Typography variant="body1">5335 Gate Pkwy, 2nd Floor</Typography>
        <Typography variant="body1">Jacksonville, FL 32256</Typography>
        <Typography variant="body1">Phone: (602) 716-5396 (M-F 7am - 3:30pm MST)</Typography>
        <Typography variant="body1">Fax: (904) 659-8905</Typography>
        <Typography variant="body1" gutterBottom>
          E-mail: support@sitebuilder.com
        </Typography>
        <Typography variant="body1" gutterBottom>
         <b>IMPORTANT NOTE: IN THE EVENT YOU SEND US A NOTICE OF ANY KIND VIA EMAIL AND DO NOT RECEIVE A 
           RESPONSE FROM SITEBUILDER, PLEASE SUBMIT A DUPLICATE COPY VIA MAIL. DUE TO THE VAGARIES OF THE 
           INTERNET AND EMAIL COMMUNICATION IN PARTICULAR, INCLUDING THE OCCASIONAL UNINTENDED EFFECTS 
           OF SPAM FILTERS, SENDING AN ALTERNATE FORM OF NOTICE WILL HELP ASSURE THAT YOUR NOTICE IS 
           RECEIVED BY SITEBUILDER AND ACTED ON IN A TIMELY MANNER. </b>
        </Typography>
        <li>
          <Typography variant="body1" gutterBottom>
          <b>Fees.</b> Sitebuilder may seek reimbursement for costs in responding to requests as provided by law 
          and may charge additional fees for costs in responding to unusual or burdensome requests
          </Typography>
        </li>
        <li>
          
        <Typography variant="body1" gutterBottom>
        <b>Available Information.</b>
          Depending on the type of formal legal process provided, Sitebuilder
          may be able to respond with one or more of the following types of
          data:
        </Typography>
        <ol type="A">
          <li>
            <Typography variant="body1" gutterBottom>
            <b>Non-Content.</b> Sitebuilder will produce non-content information such
              as basic subscriber information which may include the following
              subject to a valid request:
            </Typography>
          </li>
          <ol type="i">
            <li>
              <Typography variant="body1" gutterBottom>
                Name
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
                Email addresses
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
                Date and time of account creation
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
                Billing information
              </Typography>
            </li>
            <li>
              <Typography variant="body1" gutterBottom>
                IP Logs
              </Typography>
            </li>
          </ol>
          <li>
            <Typography variant="body1" gutterBottom>
            <b>Content.</b> Sitebuilder will only produce customer content (such as
              website files and email content) pursuant to a valid search
              warrant from an entity with proper jurisdiction.
            </Typography>
          </li>
        </ol>
        </li>
      </ol>
      <Typography variant="body2" gutterBottom>
        This file was last modified: October 07, 2022.
      </Typography>
    </DataRequestContainer>
  );
};

export default DataRequestComponent;
